import React, { useEffect } from 'react';
import './Dsdsrlsaimandscope.css';
import { NavLink, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from '../../components/Dsrls/Accordion';
import Ddsrlsarticletemplate from '../../downloads/DS-RLS-Article_Template.doc';
import Ddsrlscopyrightform from '../../downloads/DS-RLS-Copyright_Form.docx';
import Journaltitle from '../../pages/dsrls/data.json';

const Dsdsrlsaimandscope = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [{
        content: <div>
         <NavLink smooth to="/rls" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</NavLink>
            <NavLink to="/rls/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/rls/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/rls/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/rls/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/rls/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/rls/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddsrlsarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddsrlscopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
        </div>
    },
    ];
    return (

        <>
            <Helmet>
                <title>DS Reviews of Research in Life Sciences - DS-RLS</title>
                <link rel="canonical" href="https://dsjournals.com/ds-rls"/>
                <meta name="title" content="DS Reviews of Research in Life Sciences - DS-RLS"/>
                <meta name="description" content="Discover the latest research and insights in the field of reviewes of research in life sciences with DS-RLS Journal. Access cutting-edge studies and expert perspectives."/>
                <meta name="keywords" content="science journal, sci indexed journals, journal index, life science journal, science journal impact factor, life science journal impact factor, journal about life, life journal impact factor, journal issue, life and science journal. life science magazine."/>
                <meta property="og:type" content="Website"/>
                <meta property="og:site_name" content="Dream Science"/>
                <meta property="og:url" content="https://dsjournals.com/ds-rls"/>
                <meta property="og:title" content="DS Reviews of Research in Life Sciences - DS-RLS"/>
                <meta property="og:description" content="Discover the latest research and insights in the field of reviewes of research in life sciences with DS-RLS Journal. Access cutting-edge studies and expert perspectives."/>
                <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="DS Reviews of Research in Life Sciences - DS-RLS"/>
                <meta name="twitter:description" content="Discover the latest research and insights in the field of reviewes of research in life sciences with DS-RLS Journal. Access cutting-edge studies and expert perspectives."/>
                <meta name="twitter:site" content="@DreamScience4"/>
                <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
                <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Reviews-of-Research-in-Life-Sciences.webp"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>

            <section id="hero-no-slide-dsrls" className="d-flex justify-cntent-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* <h2 style={{fontSize:"25px"}}>DS Reviews of Research in Life Sciences  ( DS-RLS )</h2> */}
                                    {
                                Journaltitle.map(heading => {
                                  return(
                                    <h2 style={{fontSize:"25px"}}>{heading.title}</h2>     
                                  )
                                      
                                })
                            }
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ol>
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/journals">Journals</NavLink></li>
                            <li>DS-RLS</li>
                        </ol>
                    </div>
                </div>
            </section>
            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div id="jorunalcard">
                                    <div className="card" style={{ maxWidth: "950px" }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4">
                                                <img id="journalpfimg_p" src="assets/img/DS-Reviews-of-Research-in-Life-Sciences-Bookcover.jpg" className="img-responsive center-block d-block mx-auto" alt="journalprofileimg" />
                                            </div>
                                            <div className="col-lg-9 col-md-8">
                                                <div className="card-body">
                                                    {/* <h6 className="card-title center">DS International Journal of Computer Science and Engineering ( DSCSE )</h6> */}

                                                    <p class="card-text">
                                                        <table className='table'>
                                                            <tr className="pt-1">
                                                                <th scope="row" className="col-md-4 align-top">Editor in Chief</th>
                                                                <td className="col-md-8 px-2">  Dr. Vittorio Gentile,<br />
                                                                Associate Professor,<br/>
                                                                Department of Precision Medicine,<br/>
                                                                University of Campania &quot;Luigi Vanvitelli&quot;<br/>
                                                                Costantinopoli 16, 80138 Naples, Italy. <br/>
                                                                </td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">ISSN (Online)</th>
                                                                <td className="col-md-8 px-2">XXXX-XXXX</td>
                                                            </tr>
                                                            <tr className="mt-2">
                                                                <th scope="row" className="col-md-4">Subject</th>
                                                                <td className="col-md-8 px-2">Life Sciences</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Year of Starting</th>
                                                                <td className="col-md-8 px-2">2023</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Publication Frequency</th>
                                                                <td className="col-md-8 px-2">4 Issue per Year</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Language</th>
                                                                <td className="col-md-8 px-2">English</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Paper Submission id</th>
                                                                <td className="col-md-8 px-2">life@dsjournals.com</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4">Format of Publication</th>
                                                                <td className="col-md-8 px-2">online</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4 align-top">Publication Fee</th>
                                                                <td className="col-md-8 px-2">Free of Cost Journal</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row" className="col-md-4"> Publisher</th>
                                                                <td className="col-md-8 px-2">Dream Science</td>
                                                            </tr>
                                                        </table>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>Aim and Scope</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>DS Reviews of Research in Life Sciences (DS-RLS) is an international, peer-reviewed, quick-refereeing open-access journal addressed to researchers, 
                                Scientists, Policy makers, and working professionals who are interested in the review of studies and theoretical papers concerned with all areas of Life Sciences at any level. The journal accepts 
                                high-quality articles that solve or discuss research problems by using a review approach. This may include thematic or methodological reviews or meta-analyses. DSRLS is a new journal, and its 
                                launch offers an exciting possibility to create a journal that reflects this diversity within Life science. The journal will bring together studies employing the varied reviews and theoretical 
                                approaches that are fuelling biological discovery. This will be an inclusive journal that embraces all these disciplines and sub-disciplines. DSRLS is the home for publishing multidisciplinary 
                                reproducible life science reviews led by a team of subject experts. The journal also aims to break down siloed specialisms, bringing together all experts to foster better collaboration and 
                                information sharing across the life sciences.</p>
                                <p style={{ textAlign: 'justify', textIndent: "50px" }}>
                                DS Reviews of Research in Life Sciences (DS-RLS) is a broad-scope journal covering all areas of biology. Our content includes reviews, objective descriptions, research reports, 
                                practical programmes, short notes, news items, book reviews, reports of meetings, professional announcements, Q&A, short communications, data notes, opinion 
                                pieces/debates, commentaries, hypotheses and descriptions of new methods across the Life Sciences, with a publication policy that combines selection for broad interest 
                                and importance with a commitment to serving authors well. DSRLS welcomes the submission of negative results and replication review studies. Proposals of themed 
                                collections are encouraged. Letters to the Editor are also accepted and should discuss an analysis of an article previously published in DSRLS. Constructive criticisms 
                                and discussions of published papers and letters of relevance and interest to the readership will be published at the discretion of the Managing Editor.
                                </p>
                                <p style={{ textAlign: 'justify', fontWeight: "bold" }}>This journal covers all aspects of life sciences. The broad research fields of interest include but are not limited to:</p>
                                <p style={{ textAlign: 'justify' }}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Abiogenesis</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Agriculture</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Anatomy</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Artificial Life</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Astrobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bacteriology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biochemical Genetics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biochemistry</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bioclimatology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biodiversity</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bioengineering</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biogeography</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bioinformatics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biological Anthropology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biomaterials</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biomathematics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biomedicine</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biometry</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Bionics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biophysics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Biotechnology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Botany</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cell Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Chronobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Conservation Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Cryobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Developmental Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Ecology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Entomology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Ethnobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Ethology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Evolution and Population Genetics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Evolutionary Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Extremophiles</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Freshwater Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Genetic Engineering</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Genetic Epidemiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Genetics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Genomics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Geobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Habitability</p>
                                        </div>

                                        <div className='col-md-6'>
                                        <p><i className="bi bi-chevron-double-right"></i>Herpetology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Hydrobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Ichthyology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Immunogenetics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Immunology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Immunotechnology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Life Detection Technology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Mammalogy </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Marine Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Medical Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Metabolomics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Microbiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Molecular Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Molecular Genetics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Molecular phylogeny</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Mycology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Nanobiotechnology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Neurobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Origins of life</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Ornithology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Paleobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Palaeontology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Parasitology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>phycology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Physiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Prebiotic chemistry</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Primatology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Proteomics</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Protistology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Protozoology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Psychobiology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Space life sciences/ Space biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Structural biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Synthetic biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Systems biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Theoretical biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Tissue culture</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Toxicology </p>
                                        <p><i className="bi bi-chevron-double-right"></i>Veterinary Sciences</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Virology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Wildlife Biology</p>
                                        <p><i className="bi bi-chevron-double-right"></i>Zoology</p>
                                        </div>
                                    </div> 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Dsdsrlsaimandscope